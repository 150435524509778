import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"

import { Articles } from "../components/ArticlePage/others"
import { NavContainer } from "../components/BrandPage/brand-page.css"
import { Container, Title } from "../components/Hero/hero.css"
import Newsletter from "../components/Newsletter/newsletter"
import BreadCrumb from "../components/Breadcrumb/breadcrumb"

import {
  ArticlesContainer,
} from "../components/ArticlePage/others.css"

const Sections = ({data}) => {
  const models = data.allBuilderModels
  const page = models.page[0].data
  const articles = models.article
  const sections = models.section

    return <>
    <Helmet>
      <title>{page.title}</title>
      <meta property="og:url" content={`https://www.watchesguild.com/sections/`} />
      <meta property="og:description" content={page.description} />
      <meta name="description" content={page.description} />
      <link rel="canonical" href={`https://www.watchesguild.com/sections/`}/>
      <script type="application/ld+json">
        {`
        {"@context":"http://schema.org","@type":"BreadcrumbList","itemListElement":[
          {"@type":"ListItem","position":1,"item":{"@id":"https://www.watchesguild.com/sections","name":"Articles"}},
        ]}
        `}
      </script>
    </Helmet>
    <main
      style={{
        margin: `0 auto`,
      }} className="sectionsPage stdPage"
    >
      <div class="heroSection">
        <div class="heroContainer">
          <BreadCrumb pageName="Sections" />
          <Container className="imageContainer container">
            <Title>{page.title}</Title>
            <p>{page.description}</p>
          </Container>
        </div>
      </div>
      <nav class="heroNav">
        <div class="container">
          <NavContainer>
          {sections.map((section, index) => (
            <li key={index} ><a href={`/sections/${section.data.code}`}>{section.data.name}</a></li>
          ))}
          </NavContainer>
        </div>
      </nav>
      <div class="container">
        <ArticlesContainer className="sectionArticles Articles">
          <Articles articles={articles} />
        </ArticlesContainer>
      </div>
      <Newsletter placeholder="Your email here" submit="Subscribe" />
    </main>
  </>
  };

export default Sections

export const pageQuery = graphql`
  query{
    allBuilderModels {
      page(
        target: { urlPath: "/sections/" }
        limit: 1
        options: { cachebust: true }
      ) {
        data {
          title
          description
          template
          url
        }
      }
      section(limit: 6, query: {data: {featuredOnLanding: true}} , sort: {data: {rankOnLanding: 1}}) {
        data {
          name
          code
          description
          picture
          featured
          rank
        }
      }
      article(limit: 9, sort: {createdDate: -1}) {
        data {
          title
          slug
          summary
          picture
          writer
        }
        createdDate
      }
    }
  }
`;